var conversationsOpenButton = $('.conversations-open')
var conversationsWrapper = $('.conversations-wrapper')
var messagesScrollObject = $('.conversations-messages-inner')
var currentConversationId = null
var conversationsLoader = $('.conversations-messages-loader')
var messageInput = $('#message_body')
var messageForm = $('form[name="message"]')
var messageSubmit = messageForm.find('button')
var conversationForm = $('form[name="tableConversation"]')
var conversationSubmit = $('button.save-button')
var conversationInput = $('#tableConversation_userConversations')
var conversationsClose = $('.conversations-close')
var popupConversation = $('#popupCreateConversation')

if ($('.unread').length) {
  conversationsOpenButton.addClass('unread-all')
}
conversationsOpenButton.on('click', function () {
  if (!conversationsWrapper.hasClass('open')) {
    conversationsWrapper.addClass('open')
  }
})
conversationsClose.on('click', function () {
  conversationsWrapper.removeClass('open')
})

function scrollToBottom () {
  messagesScrollObject.scrollTop(messagesScrollObject.height())
}
function getConversationMessages (conversationId) {
  $.ajax({
    type: 'GET',
    url: Routing.generate('admin_conversation_message_list_ajax', {'conversationId': conversationId})
  })
        .done(function (response) {
          var messages = response.messages
          var messagesHtml = ''
          messages.forEach(function (e) {
            var mineClass = ''
            if (e.user.user && currentUserId == e.user.user.id) {
              mineClass = 'mine'
            }
            var messageDate = moment(e.createdAt)
            messagesHtml += '<li class="message-item ' + mineClass + '"><div class="message-item-date">' + messageDate.format('DD MMM HH:mm') + '</div><div class="message-item-text">' + e.body + '</div></li>'
          })
          $('.conversations-messages-item.active').removeClass('active')
          $('.conversations-messages-item[data-id="' + conversationId + '"]').addClass('active').find('.conversations-messages-list').empty().append(messagesHtml)
          conversationsLoader.hide()
          scrollToBottom()
        })
        .fail(function (response) {
        })
}
function createMessage (conversationId, form) {
  $.ajax({
    type: 'POST',
    url: Routing.generate('admin_conversation_message_new_ajax', {'conversationId': conversationId}),
    data: form.serialize(),
    error: function () {
      conversationsLoader.hide()
      messageInput.val('')
      messageSubmit.removeAttr('disabled')
      scrollToBottom()
      alert('something went wrong. Please reload page')
    }
  })
        .done(function (response) {
          var message = response.message
          var mineClass = ''
          if (message.user.user && currentUserId == message.user.user.id) {
            mineClass = 'mine'
          }
          var messageDate = moment(message.createdAt)
          $('.conversations-messages-item[data-id="' + conversationId + '"]').find('.conversations-messages-list').append('<li class="message-item ' + mineClass + '"><div class="message-item-date">' + messageDate.format('DD MMM HH:mm') + '</div><div class="message-item-text">' + message.body + '</div></li>')
          conversationsLoader.hide()
          messageInput.val('')
          messageSubmit.removeAttr('disabled')
          scrollToBottom()
        })
}
function createConversation (userSelected) {
  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: Routing.generate('admin_conversation_new_ajax'),
    data: {user: userSelected}
  })
        .done(function (response) {
          console.log(response)
          var conversationName = ''
          var conversation = response.conversation
          currentConversationId = conversation.id
          conversation.users.forEach(function (elem) {
            if (elem.user && currentUserId !== elem.user.id) {
              conversationName = elem.user.fullName
            }
          })
          $('.conversations-list-item.active').removeClass('active')
          $('.conversations-list-inner ul').append('<li class="conversations-list-item clearfix active" data-user-id="' + userSelected + '" data-id="' + conversation.id + '" data-loaded="1">' +
                '<div class="conversation-delete">' +
                '<span class="glyphicon glyphicon-remove"></span>' +
                '</div>' +
                '<div class="conversation-name">' +
                conversationName +
            '</div>' +
            '</li>')
          $('.conversations-messages-item.active').removeClass('active')
          $('.conversations-messages-inner').append('<div class="conversations-messages-item active" data-id="' + conversation.id + '" >' +
                '<ul class="conversations-messages-list">' +
                '</ul>' +
                '</div>')
          conversationInput.val('0')
          conversationInput.find('[value="' + userSelected + '"]').attr('disabled', 'disabled')
          conversationInput.trigger('change.select2')

          conversationsLoader.hide()
          conversationSubmit.removeAttr('disabled')
          $('.modal-backdrop').remove()
          popupConversation.modal('hide')
          checkDisabledUser()
        })
        .fail(function (response) {
        })
}
function deleteConversation (conversationId) {
  $.ajax({
    type: 'DELETE',
    url: Routing.generate('admin_conversation_delete_ajax', {'conversationId': conversationId})
  })
        .done(function (response) {
          var userId = $('[data-id="' + conversationId + '"][data-user-id]').attr('data-user-id')
          conversationInput.find('[value="' + userId + '"]').removeAttr('disabled')
          conversationInput.trigger('change.select2')
          $('[data-id="' + conversationId + '"]').remove()
          if (currentConversationId == conversationId) {
            currentConversationId = null
          }
          conversationsLoader.hide()
          checkDisabledUser()
        })
        .fail(function (response) {
        })
}
$('.conversations-list ').on('click', '.conversations-list-item', function () {
  if (!$(this).hasClass('active')) {
    $('.conversations-list-item.active').removeClass('active')
    $(this).addClass('active').removeClass('unread')
    if (!$('.unread').length) {
      $('.conversations-open').removeClass('unread-all')
    }
    currentConversationId = parseInt($(this).attr('data-id'))
    var loaded = parseInt($(this).attr('data-loaded'))
    if (!loaded) {
      $(this).attr('data-loaded', '1')
      conversationsLoader.show()
      getConversationMessages(currentConversationId)
    } else {
      $('.conversations-messages-item.active').removeClass('active')
      $('.conversations-messages-item[data-id="' + currentConversationId + '"]').addClass('active')
    }
  }
})

$('.conversations-refresh').on('click', function () {
  if (!currentConversationId) {
    alert('please choose chat')
  } else {
    conversationsLoader.show()
    getConversationMessages(currentConversationId)
  }
})

messageSubmit.on('click', function () {
  messageForm.submit()
})
messageForm.submit(function (e) {
  e.preventDefault()
  if (!currentConversationId) {
    alert('please choose chat')
  } else {
    messageInput.removeClass('error')
    var messageBody = messageInput.val().trim()
    if (messageBody == '') {
      messageInput.addClass('error')
      alert('Please enter your message')
    } else {
      conversationsLoader.show()

      messageSubmit.attr('disabled', 'disabled')
      createMessage(currentConversationId, $(this))
    }
  }
})

$('.button-add-conversation').on('click', function () {
  popupConversation.modal('show')
})

conversationSubmit.on('click', function () {
  conversationForm.submit()
})
conversationForm.submit(function (e) {
  e.preventDefault()

  conversationForm.find('button[data-toggle]').removeClass('error')
  var userSelected = conversationInput.val()
  if (userSelected == '') {
    conversationForm.find('button[data-toggle]').addClass('error')
  } else {
    conversationsLoader.show()
    conversationSubmit.attr('disabled', 'disabled')
    createConversation(userSelected)
  }
})

$('.conversations-list ').on('click', '.conversation-delete', function (e) {
  e.stopPropagation()
  if (confirm('Are you sure you want to delete chat?')) {
    var conversationId = parseInt($(this).parents('.conversations-list-item').attr('data-id'))
    conversationsLoader.show()
    deleteConversation(conversationId)
  }
})
function checkDisabledUser () {
  var conversationListUserId = $('.conversations-list-item').map(function () { return parseInt($(this).attr('data-user-id')) })
  $('select#tableConversation_userConversations').find('option').map(function () {
    if ($.inArray(parseInt($(this).val()), conversationListUserId) !== -1) {
      $(this).prop('disabled', true)
    } else {
      $(this).prop('disabled', false)
    }
    $('select#tableConversation_userConversations').trigger('change.select2')
  })
}
checkDisabledUser()
